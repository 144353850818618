//Import Images
import iphone3 from "./img/iphone3.png";
import ipad from "./img/ipad.png";
import vacations from "./img/vacations.png";
import foodjamMain from "./img/foodjamMain.png";
import vacations4 from "./img/vacations4.png";
import weatheriscoMain from "./img/weatheriscoMain.png";
import weatherisco2 from "./img/weatherisco2.png";
import foodBasket from "./img/foodBasket.png";

export const MovieState = () => {
    return [
        {
            title: "Chill Music Player",
            mainImg: ipad,
            secondaryImg: iphone3,
            url: "/work/chill-player",
            outerUrl: "https://react-music-player-1.netlify.app",
            awards: [
                {
                    title: "Jump back in",
                    description:
                        "Pick up your playlist right where you left off.",
                },
                {
                    title: "Technologies",
                    description:
                        "Built with pure React components and css animations.",
                },
                {
                    title: "Chillhop playlist",
                    description:
                        "fetching songs from the hip hop lofi api.",
                },
            ],
        },
        {
            title: "Vacation Master",
            mainImg: vacations,
            url: "/work/vacation-master",
            outerUrl: "https://oc-foodjam-1-33a93cac183f.herokuapp.com/",
            secondaryImg: vacations4,
            awards: [
                {
                    title: "Plan a Vacation",
                    description:
                        "Travel agency website to track and follow vacations.",
                },
                {
                    title: "Technologies",
                    description:
                        "Built with React class Components, Redux, NodeJS Express and mongoDB.",
                },
                {
                    title: "Authentication",
                    description:
                        "Password protected session for the user dashboard as well as analytics session for the website administrator.",
                },
            ],
        },
        {
            title: "FOODJAM",
            mainImg: foodjamMain,
            url: "/work/foodjam",
            outerUrl: "https://dashboard.heroku.com/apps/oc-foodjam-1",
            secondaryImg: foodBasket,
            awards: [
                {
                    title: "Fill your cart with goods",
                    description:
                        "An online self-service grocery store that is offering a wide variety of food.",
                },
                {
                    title: "Technologies",
                    description:
                        "Built with Angular, NodeJS Express and mongoDB.",
                },
                {
                    title: "Manage products",
                    description:
                        "Admin session to add or edit your products.",
                },
            ],
            loginDetails: {
                email: "test@test.com",
                password: "123123"
            }
        },
        {
            title: "Vacation Master",
            mainImg: vacations,
            url: "/work/vacation-master",
            outerUrl: "https://oc-vacations-265e8554bfe9.herokuapp.com/",
            secondaryImg: vacations4,
            awards: [
                {
                    title: "Plan a Vacation",
                    description:
                        "Travel agency website to track and follow vacations.",
                },
                {
                    title: "Technologies",
                    description:
                        "Built with React class Components, Redux, NodeJS Express and mongoDB.",
                },
                {
                    title: "Authentication",
                    description:
                        "Password protected session for the user dashboard as well as analytics session for the website administrator.",
                },
            ],
            loginDetails: {
                email: "Oren@gmail.com",
                password: "123456"
            }
        },
        {
            title: "Weatherisco",
            mainImg: weatheriscoMain,
            url: "/work/weatherisco",
            outerUrl: "https://oc-weatherisco.herokuapp.com/",
            secondaryImg: weatherisco2,
            awards: [
                {
                    title: "Global current weather",
                    description:
                        "Get a 5 day forecast from any city in the world",
                },
                {
                    title: "Technologies",
                    description:
                        "Built with React functional components, hooks and Redux state management.",
                },
                {
                    title: "Save your favorite cities",
                    description:
                        "keep track for the current weather of your favorite cities",
                }
            ]
        },
    ];
};
